<template>
  <div class="px-3">
   

    <!-- Button Edit Start -->
     <div class="card-body px-0" >
            <div id="ButtonSettingAccordion" >
                <div class="headingText" id="headingThree">
                    <strong class="">
                        <span>Button  Setting</span>
                        
                        <a block v-b-toggle.buttonSetting style="float: right; padding-right:20px;  cursor: pointer;" class="arrow-icon-down" data-toggle="collapse" data-target="#buttonSetting" aria-expanded="false" aria-controls="buttonSetting">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                    </strong>
                </div>
                <hr>
                <b-collapse id="buttonSetting" accordion="my-accordion" role="tabpanel">
                     <div class="">
                    
      <div class="button-position mb-2 d-flex flex-wrap align-items-center">
        <span class="editorStyleTitle mr-4"> Button position: </span>
        <div>
        <span
          type="button"
          class="mx-2 "
          :class="{ 'active-svg': block.style.position === 'left' }"
          @click="block.style.position = 'left'"
        >
          <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
              fill="#323232"
            />
          </svg>
        </span>
        
        <span
          type="button"
          class="mx-2"
          :class="{ 'active-svg': block.style.position === 'center' }"
          @click="block.style.position = 'center'"
        >
          <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
              fill="#6D6B6D"
            />
          </svg>
        </span>

        <span
          type="button"
          class="mx-2"
          :class="{ 'active-svg': block.style.position === 'right' }"
          @click="block.style.position = 'right'"
        >
          <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
              fill="#6D6B6D"
            />
          </svg>
        </span>
        </div>
      </div>

       <div class=" d-flex justify-content-between align-items-center mb-2">
        <strong class="text-dark font-weight-medium">Use Icon </strong>
        <span class=" custom-control custom-switch mt-2" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`IconTooltip-${Date.now()}`"
          v-model="block.useIcon"
        />
        <label class="custom-control-label" :for="`IconTooltip-${Date.now()}`"></label>
      </span>
      </div>  

      <div class="row align-items-center mb-2" v-if="!block.useIcon">
        <div class="col-8 pr-0">
          <div class="form-group mb-0">
            <b-form-textarea
         id="content"
          class="form-control"
        placeholder="Input text"
         v-model="block.text"
        max-rows="5"
      ></b-form-textarea>
          </div>
        </div>
        
      </div>

      <div class="row align-items-center mb-2" v-else>
        <div class="col-8 d-flex align-items-center mb-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                fill="#B3AFB6"
              />
            </svg>

            <select
              class="form-select w-100 form-control-sm  ml-1"
              aria-label="Default select example"
              v-model="selectedIcon"
            >
               <option v-for="(icon,index ) in block.Icons" :key="index" :value="icon" >{{icon}}</option>
            </select>
            <!-- <b-form-select v-model="selectedIcon"  >
              <b-form-select-option v-for="icon in block.Icons" :key="icon" :value="icon" @change="changeSelectedIcon(icon)">{{icon}}</b-form-select-option>
            </b-form-select> -->
          </div>
      </div>
      

      <a
        class="d-none"
        style="float: right; padding-right: 20px; cursor: pointer"
        data-toggle="collapse"
        :data-target="'#collapseOne' + index"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </a>
      <div>
        <div class="mb-2 row">
          <div class="col-8 d-flex align-items-center mb-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                fill="#B3AFB6"
              />
            </svg>

            <select
              class="form-select w-100 form-control-sm  ml-1"
              aria-label="Default select example"
              v-model="block.style.fontFamily"
            >
            <option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>
            </select>
          </div>
          <div class="col-12 d-flex align-items-center">
            <div class=" px-0 d-flex align-items-center">
            <span class="">
              <svg
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                  fill="#B3AFB6"
                />
                <path
                  d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <input
              type="number"
              min="0"
              max="100"
              v-model="block.style.fontSize"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>

          <div class="d-flex align-items-center px-0">
            <span
            type="button"
              class="mx-2 font-style-button" 
              :class="{ 'active-svg': block.style.fontWeight === 'bold' }"
              @click="
                block.style.fontWeight =
                  block.style.fontWeight == 'normal' ? 'bold' : 'normal'
              "
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span
            type="button"
              class="mx-2 font-style-button"
              :class="{ 'active-svg': block.style.fontStyle === 'italic' }"
              @click="
                block.style.fontStyle =
                  block.style.fontStyle == 'normal' ? 'italic' : 'normal'
              "
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span
            type="button"
              class="mx-2 font-style-button"
              :class="{
                'active-svg': block.style.textDecoration === 'underline',
              }"
              @click="
                block.style.textDecoration =
                  block.style.textDecoration == 'underline'
                    ? 'unset'
                    : 'underline'
              "
            >
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
          </div>
          </div>

          
          <!-- <label for="" class="font-weight-bold">Font Style: </label> -->
         
        </div>
        

        

        

        <div class="d-flex flex-wrap ">
          <div class="d-flex">
            <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">W</label>
            <input
              type="number"
              v-model="block.style.buttonWidth"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
            />
          </div>

          <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted mr-1">H </label>
            <input
              type="number"
              v-model="block.style.buttonHeight"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          </div>

          <div class="d-flex">
            <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8333 13.8333H15.5V15.5H13.8333V13.8333ZM13.8333 12.1667H15.5V10.5H13.8333V12.1667ZM0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM0.5 2.16667H2.16667V0.5H0.5V2.16667ZM3.83333 2.16667H5.5V0.5H3.83333V2.16667ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5ZM0.5 15.5H2.16667V13.8333H0.5V15.5ZM15.5 4.66667C15.5 2.36667 13.6333 0.5 11.3333 0.5H7.16667V2.16667H11.3333C12.7083 2.16667 13.8333 3.29167 13.8333 4.66667V8.83333H15.5V4.66667Z"
                  fill="#B3AFB6"
                />
              </svg>
            </label>
            <input
              type="number"
              v-model="block.style.borderRadius"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                  fill="#B3AFB6"
                />
              </svg>
            </label>
            <input
              type="number"
              v-model="block.style.borderSize"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          </div>
          
          
          <div class="form-group d-flexx align-items-center d-none">
            <label for="" class="mb-0 text-muted">
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.51852 3.05788L0.166656 10.4097V13.1667H2.9236L10.2755 5.81483L7.51852 3.05788ZM2.3134 11.6963H1.63703V11.0199L7.51852 5.13846L8.19489 5.81483L2.3134 11.6963ZM12.4516 3.63868C12.7383 3.35195 12.7383 2.88879 12.4516 2.60206L10.7313 0.881729C10.4446 0.595006 9.98139 0.595006 9.69467 0.881729L8.2537 2.32269L11.0107 5.07964L12.4516 3.63868Z"
                  fill="#B3AFB6"
                />
              </svg>
            </label>
            <input
              type="color"
              v-model="block.style.borderColor"
              style="min-width:50px;width: 50px"
              class="form-control-color"
            />
          </div>
        </div>


        <div>
          <span class="editorStyleTitle mr-5">Button Text : </span>
        </div>
        <div class="d-flex mb-2 flex-wrap align-items-center">
          
          <div class=" d-flex align-items-center">
            <input
              type="color"
              v-model="block.style.color"
              style="min-width:30px;width: 30px;height:30px;"
              class="form-control-color form-control border-0"
            />
           <input type="text" class="ColorTextInput" v-model="block.style.color">
          </div>
          <div class=" d-flex align-items-center ">
            <svg
              width="14" 
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              v-model="block.style.textOpacity"
              max="100"
              min="0"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="  d-flex">
            <span class="mx-2 font-style-button" type="button" 
            :class="{'active-svg': block.style.textAlign === 'left'}"
            @click="block.style.textAlign = 'left'">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span class="mx-2 font-style-button" type="button"
            :class="{'active-svg': block.style.textAlign === 'center'}"
             @click="block.style.textAlign = 'center'">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span class="mx-2 font-style-button"
            type="button"
            :class="{'active-svg': block.style.textAlign === 'right'  }"
             @click="block.style.textAlign = 'right'  ">
              <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z" fill="#B3AFB6"/>
              
            </svg>
            </span>
          </div>
          <!-- <strong class="pt-2">Text Color: </strong> -->
        </div>

        <div class="form-group d-flex mb-2 align-items-center row">
          <div class="col-12">
             <span class="editorStyleTitle mr-5">Button Background : </span>
          </div>
          <!-- <strong class="pt-2">Background Color: </strong> -->
          <div class="col-6 d-flex align-items-center">
            <input
              type="color"
              v-model="block.style.backgroundColor"
              style="min-width: 30px;width:30px;height:30px;"
              class="form-control-color form-control border-0"
            />
          <input type="text" class="ColorTextInput" v-model="block.style.backgroundColor">
            <!-- <span> {{ BgColorOutput( block.style.backgroundColor)  }}</span> -->
          </div>
          <div class="col-6 d-flex align-items-center px-0" v-if="showBgOpacity">
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              max="100"
              min="0"
              v-model="block.style.backgroundOpacity"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>

         
        </div>
      </div>

      
     

     
      <div class="row m-0 " v-if="isDesktopView">
         <h4 class="editorStyleTitle my-4">Buttons margin:</h4>
        <div class="row align-items-center mb-2">
          <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginTop"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginBottom"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
        <div class="row mb-2 mt-3 align-items-center">
          <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginLeft"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginRight"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
      </div>
      <div class="mt-2" v-else>
        <h4 class="editorStyleTitle mt-4">Mobile Button margin:</h4>
      <div class="row m-0 ">
        <div class="row align-items-center">
          <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                min="0"
                placeholder="Top"
                v-model="block.style.mobileMarginTop"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                 min="0"
                 placeholder="Bottom"
                v-model="block.style.mobileMarginBottom"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
        <div class="row mt-3">
          <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.mobileMarginLeft"
                 min="0"
                 placeholder="Left"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.mobileMarginRight"
                 min="0"
                 placeholder="Right"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
      </div>
      </div>
    </div>
                  </b-collapse>
                </div>
            </div>
    <!-- Button Edit End -->
    <!-- Button Edit Start -->
     <div class="card-body px-0" >
            <div id="TooltipSettingAccordion" >
                <div class="headingText" id="headingFour">
                    <strong class="">
                        <span>ToolTip  Setting</span>
                        
                        <a block v-b-toggle.tooltipSetting style="float: right; padding-right:20px;  cursor: pointer;" class="arrow-icon-down" data-toggle="collapse" data-target="#tooltipSetting" aria-expanded="false" aria-controls="tooltipSetting">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                    </strong>
                </div>
                <hr>
                <b-collapse id="tooltipSetting" accordion="my-accordion" role="tabpanel">
                     <div class="">
      <div class="button-position mb-2">
        <span class="editorStyleTitle mr-4"> ToolTip position: </span>
        <span
          type="button"
          class="mx-2 "
          :class="{ 'active-svg': block.style.tooltipPosition === 'top' }"
          @click="block.style.tooltipPosition = 'top'"
        >
         <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 3H16V6H6V3Z" fill="#6D6B6D"/>
</svg>
        </span>
        
        <span
          type="button"
          class="mx-2"
          :class="{ 'active-svg': block.style.tooltipPosition === 'bottom' }"
          @click="block.style.tooltipPosition = 'bottom'"
        >
         <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z" fill="#6D6B6D"/>
</svg>

        </span>

     
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-12 pr-0">
          <div class="form-group mb-0">
            <b-form-textarea
         id="content"
          class="form-control"
        placeholder="ToolTip Text"
         v-model="block.toolTipContent"
        max-rows="5"
      ></b-form-textarea>
          </div>
        </div>
        <!-- <div class="col-4">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.4815 3.51851V16.4815H3.51851V3.51851H16.4815ZM16.4815 1.66666H3.51851C2.49999 1.66666 1.66666 2.49999 1.66666 3.51851V16.4815C1.66666 17.5 2.49999 18.3333 3.51851 18.3333H16.4815C17.5 18.3333 18.3333 17.5 18.3333 16.4815V3.51851C18.3333 2.49999 17.5 1.66666 16.4815 1.66666ZM11.9815 9.87036L9.20369 13.4537L7.22221 11.0555L4.44443 14.6296H15.5555L11.9815 9.87036Z" fill="#B3AFB6"/>
          </svg>
          <span> Icon </span>
        </div> -->
      </div>
      

      <a
        class="d-none"
        style="float: right; padding-right: 20px; cursor: pointer"
        data-toggle="collapse"
        :data-target="'#collapseOne' + index"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </a>
      <div>
        <div class="mb-2 row">
          <div class="col-8 d-flex align-items-center mb-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                fill="#B3AFB6"
              />
            </svg>

            <select
              class="form-select w-100 form-control-sm  ml-1"
              aria-label="Default select example"
              v-model="block.style.tooltipFontFamily"
            >
            <option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>
            </select>
          </div>
          <div class="col-12 d-flex align-items-center">
            <div class=" px-0 d-flex align-items-center">
            <span class="">
              <svg
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                  fill="#B3AFB6"
                />
                <path
                  d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <input
              type="number"
              min="0"
              max="100"
              v-model="block.style.tooltipTextFontSize"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>

          <div class="d-flex align-items-center px-0">
            <span
            type="button"
              class="mx-2 font-style-button" 
              :class="{ 'active-svg': block.style.tooltipFontWeight === 'bold' }"
              @click="
                block.style.tooltipFontWeight =
                  block.style.tooltipFontWeight == 'normal' ? 'bold' : 'normal'
              "
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span
            type="button"
              class="mx-2 font-style-button"
              :class="{ 'active-svg': block.style.tooltipFontStyle === 'italic' }"
              @click="
                block.style.tooltipFontStyle =
                  block.style.tooltipFontStyle == 'normal' ? 'italic' : 'normal'
              "
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span
            type="button"
              class="mx-2 font-style-button"
              :class="{
                'active-svg': block.style.tooltipTextDecoration === 'underline',
              }"
              @click="
                block.style.tooltipTextDecoration =
                  block.style.tooltipTextDecoration == 'underline'
                    ? 'unset'
                    : 'underline'
              "
            >
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
          </div>
          </div>

          
          <!-- <label for="" class="font-weight-bold">Font Style: </label> -->
         
        </div>
        

        

        

        <div class="d-flex">
          <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">W</label>
            <input
              type="number"
              v-model="block.style.tooltipWidth"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
            />
          </div>

          <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">H</label>
            <input
              type="number"
              v-model="block.style.tooltipHeight"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8333 13.8333H15.5V15.5H13.8333V13.8333ZM13.8333 12.1667H15.5V10.5H13.8333V12.1667ZM0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM0.5 2.16667H2.16667V0.5H0.5V2.16667ZM3.83333 2.16667H5.5V0.5H3.83333V2.16667ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5ZM0.5 15.5H2.16667V13.8333H0.5V15.5ZM15.5 4.66667C15.5 2.36667 13.6333 0.5 11.3333 0.5H7.16667V2.16667H11.3333C12.7083 2.16667 13.8333 3.29167 13.8333 4.66667V8.83333H15.5V4.66667Z"
                  fill="#B3AFB6"
                />
              </svg>
            </label>
            <input
              type="number"
              v-model="block.style.tooltipTextBorderRadius"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <!-- <div class="form-group d-flex align-items-center">
            <label for="" class="mb-0 text-muted">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                  fill="#B3AFB6"
                />
              </svg>
            </label>
            <input
              type="number"
              v-model="block.style.tooltipTextBorderSize"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="form-group d-flexx align-items-center d-none">
            <label for="" class="mb-0 text-muted">
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.51852 3.05788L0.166656 10.4097V13.1667H2.9236L10.2755 5.81483L7.51852 3.05788ZM2.3134 11.6963H1.63703V11.0199L7.51852 5.13846L8.19489 5.81483L2.3134 11.6963ZM12.4516 3.63868C12.7383 3.35195 12.7383 2.88879 12.4516 2.60206L10.7313 0.881729C10.4446 0.595006 9.98139 0.595006 9.69467 0.881729L8.2537 2.32269L11.0107 5.07964L12.4516 3.63868Z"
                  fill="#B3AFB6"
                />
              </svg>
            </label>
            <input
              type="color"
              v-model="block.style.tooltipTextBorderColor"
              style="min-width:50px;width: 50px"
              class="form-control-color"
            />
          </div> -->
        </div>

        <div class="d-flex mb-2 row align-items-center">
          <div class="col-12">
             <span class="editorStyleTitle mr-5">ToolTip Text : </span>
          </div>
          <div class="col-5 d-flex align-items-center">
            <input
              type="color"
              v-model="block.style.tooltipTextColor"
              style="min-width:30px;width: 30px;height:30px;"
              class="form-control-color form-control border-0"
            />
           <input type="text" class="ColorTextInput" v-model="block.style.tooltipTextColor">
          </div>
          <div class="col-3 d-flex align-items-center px-0">
            <svg
              width="14" 
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              v-model="block.style.tooltipTextOpacity"
              max="100"
              min="0"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="col-4 px-0 d-flex">
            <span class="mx-2 font-style-button" type="button" 
            :class="{'active-svg': block.style.tooltipTextAlign === 'left'}"
            @click="block.style.tooltipTextAlign = 'left'">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span class="mx-2 font-style-button" type="button"
            :class="{'active-svg': block.style.tooltipTextAlign === 'center'}"
             @click="block.style.tooltipTextAlign = 'center'">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span class="mx-2 font-style-button"
            type="button"
            :class="{'active-svg': block.style.tooltipTextAlign === 'right'  }"
             @click="block.style.tooltipTextAlign = 'right'  ">
              <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z" fill="#B3AFB6"/>
              
            </svg>
            </span>
          </div>
          <!-- <strong class="pt-2">Text Color: </strong> -->
        </div>

        <div class="form-group d-flex mb-2 align-items-center row">
          <div class="col-12">
             <span class="editorStyleTitle mr-5">ToolTip Background : </span>
          </div>
          <!-- <strong class="pt-2">Background Color: </strong> -->
          <div class="col-6 d-flex align-items-center">
            <input
              type="color"
              v-model="block.style.tooltipBg"
              style="min-width: 30px;width:30px;height:30px;"
              class="form-control-color form-control border-0"
            />
          <input type="text" class="ColorTextInput" v-model="block.style.tooltipBg">
            <!-- <span> {{ BgColorOutput( block.style.backgroundColor)  }}</span> -->
          </div>
          <div class="col-3 d-flex align-items-center px-0" v-if="showBgOpacity">
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              max="100"
              min="0"
              v-model="block.style.tooltipBackgroundOpacity"
              class="form-control-sm  w-50 px-2 ml-2 input-border-style"
            />
          </div>

         
        </div>
      </div>

      
     

     
      <div class="row m-0 " v-if="isDesktopView">
         <h4 class="editorStyleTitle my-4">ToolTip padding:</h4>
        <div class="row align-items-center mb-2">
          <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.tooltipPaddingTop"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.tooltipPaddingBottom"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
        <div class="row mb-2 mt-3 align-items-center">
          <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.tooltipPaddingLeft"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex mb-0">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.tooltipPaddingRight"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
      </div>
      <div class="mt-2" v-else>
        <h4 class="editorStyleTitle mt-4">Mobile ToolTip Padding:</h4>
      <div class="row m-0 ">
        <div class="row align-items-center">
          <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                min="0"
                placeholder="Top"
                v-model="block.style.tooltipMobilePaddingTop"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                 min="0"
                 placeholder="Bottom"
                v-model="block.style.tooltipMobilePaddingBottom"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
        <div class="row mt-3">
          <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.tooltipMobilePaddingLeft"
                 min="0"
                 placeholder="Left"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.tooltipMobilePaddingRight"
                 min="0"
                 placeholder="Right"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
      </div>
      </div>
    </div>
                  </b-collapse>
                </div>
            </div>
    <!-- Button Edit End -->

  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";

export default {
  components: {
  },
  props: {
    block: Object,
    index: Number,
     isDesktopView:Boolean,
  },
  data() {
    return {
      selectedIcon:this.block.selectedIcon,
      showBgOpacity:true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };

    //   this.contentLocal = this.content;
  },
  watch:{
    selectedIcon(newVal){
      this.block.selectedIcon = newVal
    }
  },
  methods:{
    checkHexCode(color){
      if(color){
        let pattern = /^#[0-9A-F]{6}$/i;
           return  color.match(pattern) ? true : false
      }
            
        },
    BgColorOutput(value){
      let isHex = this.checkHexCode(value)
      if(isHex){
        this.showBgOpacity = true
        return value
      } else{
        this.showBgOpacity = false
        return 'Gradient input'
      }
    },
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
  }
};
</script>
<style >
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
.reset-option
{border: 0.3px solid #6D6B6D;
    box-sizing: border-box;
    border-radius: 4px;}
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
  
}
.active-svg path{
   fill: #323232;
  
}

 input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}

</style>
